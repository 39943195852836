<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h2>List all available tags here</h2>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
